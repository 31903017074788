// colour
$orange: #ff6d22;
$red: #d9534f;
$blue: #337ab7;
$green: #5cb85c;
$grey: #607d8b;

$light-1: +15%;
$light-2: +30%;
$dark-1: -15%;
$dark-2: -30%;



//hospital-jobs
$color1: #4699D4;
$color2: #0063A4;
$color3: #A1C517;

//hospital-pool
$color4: #eb6608;
$color5: #b74120;
$color6: #2c6f8d;








