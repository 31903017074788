/**
HTML TAGS
 */

html,
body {
  padding: 0;
  margin: 0;
  color: #444;
  font-size: 15px;
  height: 100%;
  overflow: hidden;
}

#navbar li li a,
#user li li a {
  font-size: 1.2rem;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.5rem;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

router-outlet + * {
  overflow: auto;
  height: 100%;

  section {
    padding: 20px 20px 60px;
  }
}

.error-text {
  color: #a80000;
}

.pi-icon-red {
  color: red;
}

.pi-icon-blue {
  color: #337ab7;
}

.pi-icon-white {
  color: white;
}

.pi-icon-black {
  color: black;
}

.multiline-handler {
  white-space: pre-line;
}

/**
PRIMENG COMPONENTS
 */

// workaround for
// https://github.com/primefaces/primeng/commit/e693e8b29656b0585a98c46d00bac496cd4791fa
// todo: remove when upgrading to PrimeNg 13
.p-dialog-mask.p-component-overlay-enter {
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition-duration: 0.2s;
  pointer-events: auto !important;
}

.p-overlaypanel-content {
  margin-top: 20px;
}

.p-tooltip {
  max-width: 500px !important;
  pointer-events: none;
}

.p-menu {
  width: initial !important;
}

.p-menuitem.p-state-highlight a,
.p-menuitem.p-state-highlight a:hover {
  background-color: #1f7ed0 !important;
}

.p-menuitem.p-state-highlight a .p-menuitem-text,
.p-menuitem.p-state-highlight a:hover .p-menuitem-text {
  color: #fff !important;
}

p-checkbox {
  display: flex;
}

.p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem !important;
}

.ng-untouched.ng-invalid:not(form) .p-inputtext,
.ng-untouched.ng-invalid:not(form) .p-dropdown {
  border-color: #1f7ed0;
}

.ng-dirty.ng-invalid:not(form) .p-inputtext,
.ng-dirty.ng-invalid:not(form) .p-dropdown {
  background: #fff2f2;
}

.p-toast-top-right {
  top: 78px;
}

.p-placeholder {
  color: #999 !important;
}

.p-tabview-nav-link {
  text-decoration: none !important;
}

::placeholder {
  color: #999 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #999 !important;
}

::-ms-input-placeholder {
  color: #999 !important;
}

a.p-button:hover, a.p-button:focus, a.p-button:active {
  color: #ffffff;
  text-decoration: none;
}

/**
HELPERS
 */
.loading-layer {
  z-index: 50;
}

.grid {
  &.margin {
    margin: 0.5rem;
  }
}

@mixin env-bg-color($env, $color) {
  body.env-#{$env} {
    header.header {
      background-color: $color;
    }

    .status-banner {
      background-color: darken($color, 20%);
    }
  }
}

@keyframes highlight-fade {
  from {
    background-color: #ff6d22;
  }
  to {
    background-color: rgba(255, 109, 34, 0.17);
  }
}

@include env-bg-color("PRODUCTION", rgb(255, 109, 34));
@include env-bg-color("MYSQL", rgb(81, 76, 77));
@include env-bg-color("PREVIEW", rgb(88, 183, 50));
@include env-bg-color("UAT", rgb(50, 122, 183));

